function createCarousel(id, numberOfItems = 2, depthIndicator = true) {
    let splide = new Splide('.' + id, {
        type: 'loop',
        perPage: numberOfItems,
        perMove: 1,
        pagination: depthIndicator,
        gap: '1rem',
        breakpoints: {
            640: {
                perPage: 1,
            },
        }
    });
    splide.mount();
}
